<template>
  <div id="app" class="container">
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="translatedHeaders" :items="translatedItems" width="auto">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th v-for="header in props.headers" :key="header.text" :colspan="header.colspan">
                  <v-text-field v-model="header.inputValue" hide-details></v-text-field>
                </th>
              </tr>
              <tr>

              </tr>
            </thead>
          </template>
          <template #item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ getBranchName(item.branchId) }}</td>
              <td v-if="item.isMaintenance == 1"><span class="maintenance">Maintenance</span></td>
              <td v-else>Normal</td>

              <td>
                <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
              </td>
              <td>
                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
              </td>
            </tr>

          </template>
        </v-data-table>
        <template>
          <div id="app" class="container">
            <v-dialog :value="messageDialog" @input="$emit('input')" max-width="590" height="632" persistent>
              <v-card class="rounded-lg">
                <v-toolbar flat color="primary" class="rounded-t-lg mb-4">
                  <div>
                    <v-toolbar-title class="text-h6 white--text">{{ $t('labels.Are_You_Sure') }}</v-toolbar-title>
                  </div>

                  <v-spacer></v-spacer>
                  <v-btn icon @click="close()" class="white--text">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-text class="d-flex align-center">
                  <span class="ml-6" style="font-size: 1rem;">{{ $t('labels.all_data_will_removed') }}</span>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn color="blue darken-1" text @click="submitDelete()">{{ $t('buttons.submit') }}</v-btn>
                  </div>

                </v-card-actions>

              </v-card>
            </v-dialog>
          </div>
        </template>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { useWarehouseStore } from "./../../store/WarehouseStore";
import { useBranchesStore } from "./../../store/BranchesStore";
export default {
  name: "DataTable",
  props: {
    items: {
      type: Array,
    },
    edit: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    headers: {
      type: Array,
    },
  },
  data() {
    return {
      warehouseStore: null,
      branchStore: null,
      messageDialog: false,
      tempItem: {},
    };
  },
  methods: {
    editItem(item) {
      this.$emit("item", item);
      this.$emit("edit", true);
    },
    deleteItem(item) {
      this.messageDialog = true;
      this.tempItem = item;
    },
    submitDelete() {
      this.warehouseStore.deleteWarehouse(this.tempItem.id);
      this.messageDialog = false;
    },
    close() {
      this.messageDialog = false;
    },
    getBranchName(id) {
      const item = this.getBranches.find((item) => item.id === id);
      return item ? item.name : "";
    },
  },
  computed: {
    getBranches() {
      return this.branchStore.getBranches;
    },
    translatedHeaders() {
      return this.$t(`warehouseHeaders`);
    },
    translatedItems() {
      return this.items.filter((item) => {
        const name = item.name;
        const branch = this.getBranchName(item.branchId);
        const type = item.isMaintenance == 2 ? "Normal" : "Maintenance";
        return (
          name
            .toLowerCase()
            .includes(this.translatedHeaders[0].inputValue.toLowerCase()) &&
          branch
            .toLowerCase()
            .includes(this.translatedHeaders[1].inputValue.toLowerCase()) &&
          type
            .toLowerCase()
            .includes(this.translatedHeaders[2].inputValue.toLowerCase())
        );
      });
    },
  },
  created() {
    this.warehouseStore = useWarehouseStore();
    this.branchStore = useBranchesStore();
    this.branchStore.setBranches();
  },
};
</script>

<style lang="scss">
.maintenance {
  width: 123px;
  height: 28px;
  top: 164px;
  left: 683px;
  gap: 0px;
  border-radius: 10px 10px 10px 10px;
  opacity: 0px;
  background-color: #323754;
  color: white;
  padding: 6px;
}
</style>
